import {
  AutoAwesome,
  DoubleArrowRounded,
  SafetyCheckRounded,
  SurfingOutlined,
  ThermostatAuto,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  FormHelperText,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
  Zoom,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Dialogs from "../Dialogs";

const checkAIUseAccepted = () => {
  if (!localStorage.getItem("ai_use_accepted")) {
    return false;
  }
  return true;
};

const viewStates = {
  acceptAIUse: "acceptAIUse",
};
const AIActionButton = ({ onClick, label, ...rest }) => {
  const [thisState, setThisState] = React.useState({
    value: "",
    state: "",
    error: null,
    editorContent: "",
    plainText: "",
    waitingForAI: false,
    aiResponse: null,
    aiUseAccepted: checkAIUseAccepted(),
  });
  const { t } = useTranslation();
  const initialized = React.useRef(false);

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, []);
  return (
    <>
      <Dialogs
        value={thisState.state}
        onClose={() => setThisState({ ...thisState, state: "" })}
        dialogs={[
          {
            value: viewStates.acceptAIUse,
            title: t("Aviso de uso de IA"),
            body: (
              <>
                <Alert severity="info">
                  <MenuList
                    sx={{
                      "& .MuiMenuItem-root ": {
                        textWrap: "wrap",
                        whiteSpace: "normal",
                      },
                    }}
                  >
                    <MenuItem>
                      <ListItemIcon>
                        <SurfingOutlined fontSize="small" color="info" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t(
                          "Este servicio utiliza inteligencia artificial para mejorar la redacci�n de textos y probar anal�ticas experimentales."
                        )}
                      </Typography>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <ThermostatAuto fontSize="small" color="warning" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t(
                          "Al hacer clic en el bot�n de IA, aceptas que el texto que ingreses ser� procesado por un modelo de lenguaje de IA."
                        )}
                      </Typography>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <SafetyCheckRounded fontSize="small" color="success" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t(
                          "El texto que ingrese no ser� almacenado ni compartido con terceros. Toda informaci�n sensible ser� retirada antes del procesamiento."
                        )}
                      </Typography>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <DoubleArrowRounded fontSize="small" color="success" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t(
                          "El resultado del procesamiento ser� mostrado en el editor de texto para su revisi�n y aceptaci�n. Revisa el texto antes de publicarlo."
                        )}
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </Alert>
                <Divider />
                <FormHelperText>
                  {t("Este mensaje no se mostrar� de nuevo")}
                </FormHelperText>
              </>
            ),
            actions: [
              {
                label: t("Cancelar"),
                color: "error",
                onClick: () => {
                  setThisState({
                    ...thisState,
                    state: "",
                    aiUseAccepted: false,
                  });
                  localStorage.removeItem("ai_use_accepted");
                },
              },
              {
                label: t("Aceptar"),
                color: "success",
                onClick: () => {
                  setThisState({
                    ...thisState,
                    state: "",
                    aiUseAccepted: true,
                  });
                  localStorage.setItem(
                    "ai_use_accepted",
                    JSON.stringify({
                      aiUseAccepted: true,
                      date: new Date(),
                    })
                  );
                },
              },
            ],
          },
        ]}
      />
      <Zoom in={true}>
        <Button
          color="primary"
          size="small"
          onClick={() => {
            if (!thisState.aiUseAccepted) {
              setThisState({
                ...thisState,
                state: viewStates.acceptAIUse,
              });
              return;
            }
            if (onClick) {
              onClick();
            }
          }}
          startIcon={<AutoAwesome />}
          {...rest}
        >
          {label}
        </Button>
      </Zoom>
    </>
  );
};

export default AIActionButton;
